import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import AddEmailV2Connection from "components/modules/connections/channels/email/components/add-email-v2-connection-modal/add-email-v2-connection.modal-registry";
import LiveChatWidgetStrategy from "components/pages/home/onboarding/saas-onboarding/components/live-chat-widget-strategy/live-chat-widget-strategy.modal-registry";
import { EventEmitter } from "events";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { selectAllConnections } from "store/modules/connections/connections.selectors";

export const useWidgetCheckListEvents = () => {
  const allConnections = useSelector(selectAllConnections);

  const { isEmailConnectionReady, isLiveChatConnectionReady } = useMemo(() => {
    return {
      // Connections
      isLiveChatConnectionReady: allConnections.find(
        (item) => item.type === "WEB_CHAT",
      ),
      isEmailConnectionReady: allConnections.find(
        (item) => item.type === "EMAIL_V2",
      ),
    };
  }, [allConnections]);

  const { changePanelState, changePanelStateWithName } = useModalPanels();

  const onWidgetCheckListEvents = useCallback(
    (eventName: string) => {
      if (eventName === "live-chat-installation") {
        changePanelState(LiveChatWidgetStrategy, true, {});
      } else if (eventName === "email-setup") {
        if (isEmailConnectionReady) {
          changePanelStateWithName(
            "configurations",
            true,
            {},
            {
              configurations: `MANAGE_CHANNELS/${isEmailConnectionReady.id}`,
            },
          );
        } else {
          changePanelState(AddEmailV2Connection, true, {
            isOnboardingFlow: true,
          });
        }
      }
    },
    [changePanelState, changePanelStateWithName, isEmailConnectionReady],
  );

  const eventCallbackRef = useRef(onWidgetCheckListEvents);
  eventCallbackRef.current = onWidgetCheckListEvents;

  useEffect(() => {
    let widget = (window as any).ccWidget;
    let timer: any | null = null;
    const addEventListener = () => {
      if (widget?.events) {
        (widget.events as EventEmitter).addListener(
          "check-list-event",
          eventCallbackRef.current,
        );
      }
    };

    const removeEventListener = () => {
      if (widget?.events) {
        (widget.events as EventEmitter).removeListener(
          "check-list-event",
          eventCallbackRef.current,
        );
      }
    };

    if (widget) {
      addEventListener();
    } else {
      timer = setInterval(() => {
        if ((window as any).ccWidget?.events) {
          widget = (window as any).ccWidget;
          addEventListener();
          clearInterval(timer!);
          timer = null;
        }
      }, 2000);
    }

    return () => {
      if (timer) clearInterval(timer);
      removeEventListener();
    };
  }, []);
};
